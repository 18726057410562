import isEmpty from 'lodash.isempty'
import {
  baseUrl,
  destinationsSegment,
  hotelsSegment,
  resultsSegment,
} from 'src/constants'
import {
  constructDates,
  constructGeocoder,
  constructGuests,
  getGeocoderLatitude,
  getGeocoderLongitude,
} from 'src/utils'
import type { HotelsSchemaMarkupsProps } from './types'
import { constructArguments } from '../SearchHotels/searchHotelsUtils'

export const homepageSchemaMarkup = {
  '@context': 'https://schema.org/',
  '@type': 'Organization',
  '@id': 'https://www.travelpass.com/#organization',
  url: baseUrl,
  sameAs: [
    'https://www.linkedin.com/company/travelpass-group',
    'https://www.facebook.com/explorewithtravelpass',
    'https://www.tiktok.com/@explorewithtravelpass?lang=en',
    'https://instagram.com/explorewithtravelpass',
    'https://twitter.com/withtravelpass',
    'https://www.pinterest.com/explorewithtravelpass',
  ],
  logo: 'https://static.travelpass.com/assets/logo.webp',
  name: 'Travelpass',
  description:
    'Travelpass seeks to open new doors, create a more interconnected and understanding world, and enrich people’s lives through travel and experiences.',
  telephone: '888-978-6847',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '4700 West Daybreak Parkway, Suite 100 N',
    addressLocality: 'South Jordan, UT',
    addressCountry: 'United States',
    postalCode: '84009',
  },
}

export const breadcrumbSchemaMarkup = (
  pathname: string,
  searchParams: URLSearchParams
) => {
  const isSearchResultsRoute =
    pathname.includes(hotelsSegment) && pathname.includes(resultsSegment)
  const isDestinationsRoute = pathname.includes(destinationsSegment)

  const adults = searchParams.get('adults')
  const arrival = searchParams.get('arrival')
  const departure = searchParams.get('departure')
  const kids = searchParams.get('kids')
  const latitude = searchParams.get('latitude')
  const longitude = searchParams.get('longitude')
  const placeId = searchParams.get('placeId')
  const placeName = searchParams.get('location')

  const destinationName = !isEmpty(placeName)
    ? `Destinations in ${placeName}`
    : 'Hotel Details'

  const breadcrumbItems = [
    {
      '@type': 'ListItem',
      position: 1,
      item: {
        '@id': baseUrl,
        name: 'Home',
      },
    },
  ]

  if (isSearchResultsRoute) {
    breadcrumbItems.push({
      '@type': 'ListItem',
      position: 2,
      item: {
        '@id': isSearchResultsRoute
          ? window.location.href
          : `${baseUrl}${constructArguments({
              dates: constructDates({ arrival, departure }),
              geocoder: constructGeocoder({
                latitude: getGeocoderLatitude(latitude),
                longitude: getGeocoderLongitude(longitude),
                placeId,
                placeName,
              }),
              guests: constructGuests({ adults, kids }),
              searchParams,
            })}`,
        name: `Search Results for ${placeName}`,
      },
    })
  }

  if (isDestinationsRoute) {
    breadcrumbItems.push({
      '@type': 'ListItem',
      position: 2,
      item: {
        '@id': baseUrl + window.location.pathname,
        name: destinationName,
      },
    })
  }

  return {
    '@context': 'https://schema.org/',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbItems,
  }
}

export const hotelsSchemaMarkup = (
  hotelsSchemaMarkups: HotelsSchemaMarkupsProps
) => {
  const {
    address,
    averageOverall,
    city,
    country,
    description,
    image,
    name,
    postalCode,
    rating,
    stateCode,
    totalReviews,
  } = hotelsSchemaMarkups ?? {}

  const locality = `${city}, ${stateCode}`

  return {
    '@context': 'https://schema.org/',
    '@type': 'Hotel',
    name: name,
    description: description,
    address: {
      '@type': 'PostalAddress',
      streetAddress: address,
      addressLocality: locality,
      addressCountry: country,
      postalCode: postalCode,
    },
    photo: image,
    aggregateRating: {
      '@type': 'AggregateRating',
      bestRating: 10,
      ratingValue: averageOverall,
      reviewCount: totalReviews,
    },
    starRating: {
      '@type': 'Rating',
      ratingValue: rating,
    },
  }
}
