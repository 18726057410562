const defaultCollectionImage =
  'https://static.travelpass.com/assets/collections/default_collection_image.webp'

const defaultLocationImage =
  'https://static.travelpass.com/assets/default-location-img.webp'

const defaultProfileImage =
  'https://static.travelpass.com/assets/users/default-avatar-lg.webp'

export { defaultLocationImage, defaultCollectionImage, defaultProfileImage }
