import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const upsertProfileMutation = gql(`
  mutation UpsertCurrentUserProfile($input: UpsertProfileInput!) {
    upsertProfile(input: $input) {
      userProfile {
        id
        accountHandle
        activeBannerImage {
          id
          extraSmallUrl
          smallUrl
          mediumUrl
          largeUrl
        }
        bio
        bragContinents
        bragCountries
        bragStates
        displayName
        followCount {
          countOfFollowing
          countOfFollowed
        }
        followingUsers {
          id
          accountHandle
        }
        introVideoEmbed
        isFollowed
        isUserOwner
        links {
          title
          url
        }
        profileCompletion {
          completionPercentage
          hasBio
          hasProfileImage
          hasSocialLinks
          hasTravelStats
          hasTravelStyle
        }
        profileImage {
          id
          source
          type
          url
        }
        profileImageSource
        profileImageUrl
        socialLinks {
          identifier
          network
        }
        tags {
          id
          category
          name
        }
        userId
      }
    }
  }
`)

export const useUpsertCurrentUserProfileMutation = () =>
  useMutation(upsertProfileMutation)
