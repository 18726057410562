import { Icon } from '@travelpass/design-system'
import classNames from 'classnames'
import { maxReviewScore } from 'src/constants/user'

type ColorMap = {
  [key: number]: string
  default: string
}

interface ReviewBadgeProps {
  /**
   * @desc key should be a target number. The order of the properties does not matter. The value of the highest key that is less than or equal to the review average will be used.
   * @desc value should be in the format 'bg-[color]'.
   */
  colorMap?: ColorMap
  customerReviewScore: number
  size?: 'small' | 'medium'
  maxScore?: number
  totalCustomerReviews?: number
  variant?: 'filled' | 'text'
}

// function that takes in colormapping keys and returns the color mapping value based on the review average
const decideBadgeBackgroundColor = (
  colorMap: ColorMap,
  reviewScoreAverage: number
) => {
  let returnKey: string | number = 'default'
  // sorts all the keys except 'default' in descending order
  const sortedReviewScores = Object.keys(colorMap)
    ?.filter(key => key !== 'default')
    ?.map(key => Number(key))
    ?.sort((a, b) => b - a)

  for (let i = 0; i < sortedReviewScores.length; i++) {
    if (reviewScoreAverage >= sortedReviewScores[i]) {
      returnKey = sortedReviewScores[i]
      break
    }
  }

  return colorMap[returnKey]
}

export const ReviewBadge = ({
  colorMap,
  customerReviewScore,
  maxScore = maxReviewScore,
  size = 'medium',
  totalCustomerReviews,
  variant = 'filled',
}: ReviewBadgeProps) => {
  const constructedTotalCustomerReviews = `${totalCustomerReviews} ${
    totalCustomerReviews > 1 ? 'Reviews' : 'Review'
  }`

  const reviewScoreAverage = (customerReviewScore / maxScore) * 10
  const reviewBadgeBackgroundColor =
    colorMap && variant !== 'text'
      ? decideBadgeBackgroundColor(colorMap, reviewScoreAverage)
      : ''

  const inlinedReviewBadgeScoreCss = classNames(
    'flex flex-row gap-1 items-center justify-center',
    {
      'py-0.5 px-1.5 text-xs leading-none': size === 'small',
      'py-3px px-1.5 leading-none font-700 font-archivo': size !== 'small',
    },
    {
      'rounded-md color-white': variant !== 'text',
      'bg-forest': variant !== 'text' && !colorMap,
    }
  )

  return (
    <div className='flex flex-row items-center gap-2'>
      <div
        className={
          inlinedReviewBadgeScoreCss + ' ' + reviewBadgeBackgroundColor
        }
      >
        <div>
          {customerReviewScore}/{maxScore}
        </div>
        <div>
          <Icon name='thumbUpAlt' size='small' />
        </div>
      </div>
      {totalCustomerReviews && (
        <div className='type-body-2 font-400 c-grey-700'>
          {constructedTotalCustomerReviews}
        </div>
      )}
    </div>
  )
}
