import { useState } from 'react'
import { Link } from '@travelpass/design-system'
import { HotelCancellationModal, HotelPoliciesModal } from '../HotelPolicies'
import type { HotelCancellationProps } from '../HotelPolicies'
import type { HotelPoliciesProps } from '../HotelPolicies/types'

interface BookingAgreementProps {
  cancellationData: HotelCancellationProps
  policyData: HotelPoliciesProps
}

const BookingAgreement = ({
  cancellationData,
  policyData,
}: BookingAgreementProps): JSX.Element => {
  const [isCancellationModalOpen, setIsCancellationModalOpen] = useState(false)
  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(false)
  const {
    fullyRefundableUntil,
    nonRefundableAfter,
    partiallyRefundableUntil,
    policies: cancellationPolicies,
  } = cancellationData ?? {}
  const {
    checkinInstructions,
    checkinSpecialInstructions,
    fees,
    policies,
    ratePolicies,
  } = policyData ?? {}

  return (
    <>
      <div className='type-small-text' data-testid='BookingAgreement'>
        I understand that my credit card will be charged upon submitting the
        reservation request, I have reviewed the{' '}
        <Link
          label='Hotel Policies'
          onClick={() => setIsPolicyModalOpen(true)}
        />
        , and{' '}
        <Link
          label='Cancellation Policy'
          onClick={() => setIsCancellationModalOpen(true)}
        />
        , and agree to the{' '}
        <Link href='/info/terms' label='Terms And Conditions' target='_blank' />
        .
      </div>
      {isCancellationModalOpen && (
        <HotelCancellationModal
          fullyRefundableUntil={fullyRefundableUntil}
          nonRefundableAfter={nonRefundableAfter}
          partiallyRefundableUntil={partiallyRefundableUntil}
          policies={cancellationPolicies}
          onClose={() => setIsCancellationModalOpen(false)}
        />
      )}
      {isPolicyModalOpen && (
        <HotelPoliciesModal
          checkinInstructions={checkinInstructions}
          checkinSpecialInstructions={checkinSpecialInstructions}
          fees={fees}
          policies={policies}
          ratePolicies={ratePolicies}
          onClose={() => setIsPolicyModalOpen(false)}
        />
      )}
    </>
  )
}

export { BookingAgreement }
export type { BookingAgreementProps }
