import { useState } from 'react'
import { Icon } from '@travelpass/design-system'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { RefundType } from 'src/__generated__/graphql'
import type { HotelCancellationShortText } from 'src/utils'
import { HotelCancellationCardLoading } from './HotelCancellationCardLoading'
import type { HotelCancellationProps } from '../HotelCancellationModal'
import { HotelCancellationModal } from '../HotelCancellationModal'

interface HotelCancellationCardProps extends HotelCancellationProps {
  refundType?: RefundType
  subtitle?: HotelCancellationShortText
  loading?: boolean
}

const { Full, None, Partial, PartialWithFullPeriod } = RefundType

export const HotelCancellationCard = ({
  fullyRefundableUntil,
  nonRefundableAfter,
  partiallyRefundableUntil,
  policies,
  refundType,
  subtitle,
  loading = false,
}: HotelCancellationCardProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const showSubtitle = !!subtitle && refundType !== None

  const constructedTitle = () => {
    if (refundType === Full) return 'Free Cancellation'
    if (refundType === Partial || refundType === PartialWithFullPeriod)
      return 'Partial Cancellation'
    return 'Non Refundable'
  }

  const hotelCancellationCardTitleCss = ({
    refundType,
  }: {
    refundType: RefundType
  }) =>
    classNames('font-700 text-body-1', {
      'color-forest-light': refundType === Full,
      'color-canyon-dark':
        refundType === Partial || refundType === PartialWithFullPeriod,
      'color-forest': refundType === None,
    })

  if (loading) return <HotelCancellationCardLoading />

  return (
    <>
      {isModalOpen && (
        <HotelCancellationModal
          fullyRefundableUntil={fullyRefundableUntil}
          nonRefundableAfter={nonRefundableAfter}
          partiallyRefundableUntil={partiallyRefundableUntil}
          policies={policies}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className='b-grey-300 b-solid b-1 rounded-2 flex cursor-pointer items-center gap-4 p-4'
        onClick={() => setIsModalOpen(true)}
      >
        <Icon name='event' size={isMobile ? 'medium' : 'large'} />
        <div className='flex w-full flex-col'>
          <p className={hotelCancellationCardTitleCss({ refundType })}>
            {constructedTitle()}
          </p>
          {showSubtitle && (
            <p className='color-grey-800 text-body-2'>{subtitle}</p>
          )}
        </div>
      </div>
    </>
  )
}
