import { SocialNetwork } from 'src/__generated__/graphql'
import { defaultProfileImage, profilePath } from 'src/constants'
import { getImageResized } from './imagesUtils'

const getProfileAvatarAlt = ({
  accountHandle,
  displayName,
}: {
  accountHandle: string
  displayName: string
}) => `${displayName || accountHandle}'s profile picture`

const getProfileOwnerImage = (profileImageUrl: string) =>
  getImageResized({
    imageUrl: profileImageUrl ?? defaultProfileImage,
    shape: 'square',
    width: 300,
  })

const getProfileOwnerName = ({
  accountHandle,
  displayName,
}: {
  accountHandle: string
  displayName: string
}) => {
  if (displayName) return displayName

  if (accountHandle) return accountHandle

  return 'Anonymous'
}

const getProfileOwnerUrl = (accountHandle: string) => {
  if (accountHandle) return `${profilePath}/${accountHandle}`

  return profilePath
}

const getProfilePageUrl = (accountHandle?: string) => {
  if (accountHandle) return `${profilePath}/${accountHandle}`

  return profilePath
}

const getSocialPlatformIcon = (platform: SocialNetwork) => {
  switch (platform) {
    case SocialNetwork.Facebook:
      return 'facebook'
    case SocialNetwork.Twitter:
      return 'x'
    case SocialNetwork.Instagram:
      return 'instagram'
    case SocialNetwork.Linkedin:
      return 'linkedIn'
    case SocialNetwork.Tiktok:
      return 'tikTok'
    default:
      'warningAmber' // We don't know what this social platform is
  }
}

const identifySocialLink = (link: string): string | undefined => {
  const patterns: {
    [key in SocialNetwork]: RegExp
  } = {
    FACEBOOK: /(?:http:\/\/|https:\/\/)?(?:www\.)?facebook\.com(?:\/.*)?/i,
    TWITTER:
      /(?:http:\/\/|https:\/\/)?(?:www\.)?(twitter\.com|x\.com)(?:\/.*)?/i, // Adjusted for Twitter now known as X
    INSTAGRAM: /(?:http:\/\/|https:\/\/)?(?:www\.)?instagram\.com(?:\/.*)?/i,
    TIKTOK: /(?:http:\/\/|https:\/\/)?(?:www\.)?tiktok\.com(?:\/.*)?/i,
    LINKEDIN: /(?:http:\/\/|https:\/\/)?(?:www\.)?linkedin\.com(?:\/.*)?/i,
  }

  for (const platform in patterns) {
    if (patterns[platform].test(link)) return platform
  }

  return undefined // Link does not match any of the specified platforms
}

export {
  getProfileAvatarAlt,
  getProfileOwnerImage,
  getProfileOwnerName,
  getProfileOwnerUrl,
  getProfilePageUrl,
  getSocialPlatformIcon,
  identifySocialLink,
}
