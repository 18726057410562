import { useLazyQuery } from '@apollo/client'
import { gql } from 'src/__generated__'

const getPresignedUrlQuery = gql(`
  query GetPresignedUrl($presignedUrlType: PresignedUrlType!) {
    getPresignedUrl(presignedUrlType: $presignedUrlType) {
      url
    }
  }
`)

export const useGetPresignedUrlLazyQuery = () =>
  useLazyQuery(getPresignedUrlQuery)
