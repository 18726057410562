import type { DefaultContext, MutationHookOptions } from '@apollo/client'
import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'
import type {
  Exact,
  FollowProfileMutation,
  FollowUserInput,
} from 'src/__generated__/graphql'

const followUserMutation = gql(`
  mutation followProfile($input: FollowUserInput!){
    followUser(input: $input) {
      userProfile {
        ...FollowUserProfileFields
      }
    }
  }
`)

export const useFollowMutation = (
  options: MutationHookOptions<
    FollowProfileMutation,
    Exact<{ input: FollowUserInput }>,
    DefaultContext
  >
) => useMutation(followUserMutation, options)
