import { useMutation } from '@apollo/client'
import { gql } from 'src/__generated__'

const unfollowUserMutation = gql(`
  mutation unfollowProfile($input: UnfollowUserInput!){
    unfollowUser(input: $input) {
      userProfile {
        ...FollowUserProfileFields
      }
    }
  }
`)

export const useUnfollowMutation = options =>
  useMutation(unfollowUserMutation, options)
