import { Icon, ModalNext } from '@travelpass/design-system'
import type { Lodging } from 'src/__generated__/graphql'
import {
  constructAdditionalHotelAmenities,
  constructPopularHotelAmenities,
} from 'src/utils'

interface HotelAmenitiesModalNewProps {
  amenities: Lodging['amenities']
  onClose(): void
}

export const HotelAmenitiesModalNew = ({
  amenities,
  onClose,
}: HotelAmenitiesModalNewProps) => {
  const updatedAmenities = constructAdditionalHotelAmenities(amenities)

  const additionalAmenities = updatedAmenities?.map((amenity, index) => (
    <li key={`${amenity}-${index}`} className='type-body-1 c-forest truncate'>
      {amenity}
    </li>
  ))

  const popularAmenities = constructPopularHotelAmenities(amenities)?.map(
    ({ icon, label }, index) => (
      <div
        key={`${label}-${index}`}
        className='type-body-1 c-forest flex flex-row items-center gap-2'
      >
        <Icon name={icon} size='small' />
        <p>{label}</p>
      </div>
    )
  )

  return (
    <ModalNext size='medium' onClose={onClose}>
      <div className='rebrand space-y-3'>
        <h2 className='type-h2-mobile pb-2'>Amenities</h2>
        <section className='px-13 bg-warm-grey rd-3 space-y-5 py-4'>
          <h5 className='type-h5-mobile c-forest-light'>Popular</h5>
          <ul className='auto-flow-dense m-0 grid list-inside grid-cols-2 gap-x-4 gap-y-2 p-0'>
            {popularAmenities}
          </ul>
        </section>
        <section className='bg-warm-grey rd-3 space-y-5 px-8 py-4'>
          <h5 className='type-h5-mobile c-forest-light px-5'>Additional</h5>
          <ul className='auto-flow-dense m-0 grid list-inside grid-cols-2 gap-x-4 gap-y-2 p-0'>
            {additionalAmenities}
          </ul>
        </section>
      </div>
    </ModalNext>
  )
}
