import { useQuery } from '@apollo/client'
import { gql } from 'src/__generated__'
import type { GetPredefinedGuideTagsQuery } from 'src/__generated__/graphql'

const getPredefinedProfileTagsQuery = gql(`
  query GetPredefinedProfileTags {
    getPredefinedProfileTags {
      id
      category
      name
    }
  }
`)

interface UseGetPredefinedProfileTagsQuery {
  availableTags: GetPredefinedGuideTagsQuery['getPredefinedGuideTags']
  availableTagsLoading: boolean
}

const useGetPredefinedProfileTagsQuery =
  (): UseGetPredefinedProfileTagsQuery => {
    const { data, loading: availableTagsLoading } = useQuery(
      getPredefinedProfileTagsQuery
    )

    return {
      availableTags: data?.getPredefinedProfileTags,
      availableTagsLoading,
    }
  }

export { getPredefinedProfileTagsQuery, useGetPredefinedProfileTagsQuery }
