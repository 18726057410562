import type { ReactElement } from 'react'
import type { ButtonVariant, PopoverProps } from '@travelpass/design-system'
import { Button, MenuItem, Popover } from '@travelpass/design-system'
import { dispatchToggleAddToCollectionsModal } from './dispatchToggleAddToCollectionsModal'
import { dispatchToggleAddToGuideModal } from '../Guides/dispatchToggleAddToGuideModal'

interface AddToMenuProps {
  customTrigger?: ReactElement
  variant?: ButtonVariant
  item: AddToItem
  placement?: PopoverProps['placement']
}

export const AddToMenu = ({
  customTrigger,
  item,
  variant = 'white',
  placement = 'bottom',
}: AddToMenuProps) => (
  <Popover
    placement={placement}
    trigger={
      customTrigger ? (
        customTrigger
      ) : (
        <Button size='small' startIcon='addCircleOutline' variant={variant}>
          Add To
        </Button>
      )
    }
  >
    <MenuItem
      label='Add to Guide'
      startIcon='listAlt'
      value='addToGuide'
      onClick={() => dispatchToggleAddToGuideModal({ isModalOpen: true, item })}
    />
    <MenuItem
      label='Add to Collection'
      startIcon='bookmarkBorder'
      value='addToCollection'
      onClick={() =>
        dispatchToggleAddToCollectionsModal({ isModalOpen: true, item })
      }
    />
  </Popover>
)
