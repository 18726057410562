export const getYoutubeVideoIdFromLink = (link: string) => {
  if (!link) return ''

  if (link?.includes('embed'))
    return link?.split('embed/')[1]?.split('?')?.[0] ?? ''

  if (link?.includes('watch'))
    return link?.split('v=')[1]?.split('&')?.[0] ?? ''

  if (link?.includes('shorts'))
    return link?.split('shorts/')[1]?.split('?')?.[0] ?? ''

  if (link?.includes('youtu.be'))
    return link?.split('youtu.be/')[1]?.split('?')?.[0] ?? ''

  return ''
}
