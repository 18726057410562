import { env, Environment } from 'src/utils'

const imageConfigInt = {
  bucket: 'tpg-user-uploaded-int',
  awsBaseUrl: 'amazonaws.com/tpg-user-uploaded-int/',
  apiEndpoint: 'https://d2gn6l1l3af4vl.cloudfront.net/',
}

const imageConfigStg = {
  bucket: 'tpg-user-uploaded-stg',
  awsBaseUrl: 'amazonaws.com/tpg-user-uploaded-stg/',
  apiEndpoint: 'https://d1pai0g3e5wgli.cloudfront.net/',
}

const imageConfigPrd = {
  bucket: 'tpg-user-uploaded-prd',
  awsBaseUrl: 'amazonaws.com/tpg-user-uploaded-prd/',
  apiEndpoint: 'https://d39ul2s1l1555v.cloudfront.net/',
}

const imagesConfigObject = {
  [Environment.INT]: imageConfigInt,
  [Environment.STG]: imageConfigStg,
  [Environment.PROD]: imageConfigPrd,
}

export const imagesConfig = imagesConfigObject[env]
