import classNames from 'classnames'
import { getYoutubeVideoIdFromLink } from 'src/utils'

interface VideoPreviewProps {
  embedVideoLink: string
  fullWidth?: boolean
  height?: string
}

export const VideoPreview = ({
  embedVideoLink,
  fullWidth,
  height = '205',
}: VideoPreviewProps) => {
  const validYoutubeLink = getYoutubeVideoIdFromLink(embedVideoLink)

  if (!validYoutubeLink) return

  return (
    <div
      className={classNames(
        'rounded-4 min-w-77 flex justify-center overflow-hidden align-middle',
        {
          'lg:w-full': fullWidth,
        }
      )}
    >
      <iframe
        allowFullScreen
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        className={classNames({
          'lg:w-full': fullWidth,
        })}
        height={height}
        referrerPolicy='strict-origin-when-cross-origin'
        src={`https://www.youtube.com/embed/${validYoutubeLink}?controls=1&enablejsapi=1`}
        title='YouTube video player'
      ></iframe>
    </div>
  )
}
